<template>
  <div class="animated fadeIn">
    <v-col cols="12" class="mx-auto" v-if="pageLoaded">
      <v-card-title
        style="background-color: transparent !important"
        class="ps-3 pe-0"
      >
        <v-row style="padding-left: 10px !important" class="mx-auto">
          <v-col> <h3>نوبت دهی</h3></v-col>
          <v-col
            ><v-btn class="primary-btn float-end me-5" @click="openAdd()"
              >افزودن زمان آزاد</v-btn
            ></v-col
          >
        </v-row>
      </v-card-title>
      <div class="py-2">
        <v-card-text>
          <v-expansion-panels v-model="opens" accordion>
            <v-expansion-panel style="border-radius: 15px" readonly>
              <v-expansion-panel-header
                class="primary--text"
                style="font-size: 20px !important; cursor: default"
              >
                انتخاب زمان
              </v-expansion-panel-header>
              <v-expansion-panel-content class="my-3">
                <v-row>
                  <v-col cols="12" md="4" >
                    <div
                      class="cyan lighten-5 pa-5 mx-3"
                      style="border-radius: 20px"
                    >
                      <div>
                        <v-icon small class="green--text text--darken-4"
                          >mdi-circle</v-icon
                        >
                        <span class="h4 grey--text text--darken-3">
                          کلیه زمان‌ها در این روز آزاد هستند.
                        </span>
                      </div>
                      <div class="my-3">
                        <v-icon small class="orange--text">mdi-circle</v-icon>
                        <span class="h4 grey--text text--darken-3">
                          در این روز کلیه زمان‌‌ها پر نشده‌اند.
                        </span>
                      </div>
                      <div>
                        <v-icon small class="red--text">mdi-circle</v-icon>
                        <span class="h4 grey--text text--darken-3">
                          کلیه زمان‌ها در این روز پر هستند.
                        </span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row style="justify-content: space-between">
                  <v-col md="5" cols="12" :class="deviceType == 'mobile' ? 'px-0' : 'pa-8'">
                    <div class="calendar">
                      <div
                        class="header d-flex justify-content-center align-items-center"
                      >
                        <div
                          v-if="year != currentYear || month != currentMonth"
                          class="calendar-btn"
                        >
                          <v-btn
                            elevation="0"
                            height="30"
                            class="white fs-xsmall pa-0"
                            @click="showNow"
                            ><span class="c-primary">اکنون</span></v-btn
                          >
                        </div>

                        <v-row class="px-4">
                          <v-col class="text-center">
                            <span
                              id="yearPicker"
                              class="year white--text mb-1 pointer h1"
                              ><v-icon class="me-1 white--text"
                                >fa fa-pencil-square-o</v-icon
                              >
                              {{ year }}
                            </span>
                          </v-col>
                        </v-row>
                        <date-picker
                          element="yearPicker"
                          v-model="year"
                          type="year"
                          :auto-submit="true"
                          :min="currentYear"
                          @change="getNumberOfDays()"
                        >
                        </date-picker>
                      </div>
                      <div class="month py-2">
                        <span
                          id="monthPicker"
                          class="mb-0 pointer primary--text h4"
                        >
                          <v-icon class="me-1 primary--text"
                            >fa fa-pencil-square-o</v-icon
                          >
                          {{ persianMonthName(month) }}
                        </span>
                      </div>
                      <date-picker
                        element="monthPicker"
                        v-model="month"
                        type="month"
                        :auto-submit="true"
                        :min="year == currentYear ? currentMonth : ''"
                        @change="getNumberOfDays()"
                      >
                      </date-picker>

                      <div class="fit-content pa-5">
                        <div>
                          <div
                            v-for="(day, index) in weekDays"
                            :key="index"
                            class="weekday-box d-inline-block blue-grey--text text--darken-3"
                            style="transform: rotate(-50deg)"
                          >
                            {{ day }}
                          </div>
                        </div>

                        <div
                          v-for="(number, index) in numberOfDay"
                          :key="index"
                          class="day-box d-inline-block"
                          :class="
                            checkDate(number)
                              ? computedDayColor(number)
                              : 'disabled'
                          "
                          :style="
                            index == 0
                              ? 'margin-right:' +
                                (deviceType == 'mobile' ? 33 : 57) *
                                  numberOfWeekDay.value +
                                'px;'
                              : ''
                          "
                          @click="selectDate(number)"
                        >
                          <div
                            class="semi-number"
                            v-if="isSemi(number) && checkDate(number)"
                          >
                            <span>{{ computedLeftTimes(number) }}</span>
                          </div>
                          {{ number }}
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <transition name="fade">
                    <v-col v-if="showTimes" md="6" cols="12" class="px-5">
                      <v-card class="date-result pa-6">
                        <h6 class="font-weight-bold primary--text">
                          {{
                            " زمان‌های پزشک در تاریخ" +
                            " " +
                            this.day +
                            " " +
                            persianMonthName(this.selectedMonth) +
                            " " +
                            this.selectedyear
                          }}
                        </h6>
                        <hr />
                        <div class="d-flex flex-row mt-">
                          <p class="fs-medium grey--text text--darken-1">
                            زمان یا زمان‌های مورد نظر را انتخاب کنید:
                          </p>

                          <div v-if="schedules.length" class="ps-2">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  style="font-size: 26px !important"
                                  @click="colorsGuidance = true"
                                  class="dark--text pointer"
                                  v-bind="attrs"
                                  v-on="on"
                                  >mdi-information-outline</v-icon
                                >
                              </template>
                              <span class="white--text"> راهنمای رنگ‌ها </span>
                            </v-tooltip>
                          </div>
                        </div>
                        <div v-if="timeLoading" class="w-100 text-center pa-5">
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                          <h5 class="primary--text mt-3">در حال بارگذاری</h5>
                        </div>
                        <div v-else-if="!timeLoading" class="times-box py-3">
                          <span
                            class="mx-2"
                            v-for="(item2, index2) in allTimes"
                            :key="index2"
                          >
                            <v-btn
                              elevation="0"
                              :class="
                                item2.status == 'catched' ||
                                item2.status == 'canceled' ||
                                item2.status == 'passed'
                                  ? 'disabled'
                                  : ''
                              "
                              :color="
                                item2.status == 'catched'
                                  ? 'red darken-1'
                                  : item2.status == 'free'
                                  ? 'green lighten-1'
                                  : item2.status == 'canceled'
                                  ? 'black darken-2'
                                  : item2.status == 'passed'
                                  ? 'grey'
                                  : 'yellow darken-2'
                              "
                              class="py-5 mb-2"
                              style="border-radius: 7px; width: 120px"
                              @click="
                                pick(
                                  year + '/' + month + '/' + day,
                                  index2,
                                  item2.status
                                )
                              "
                            >
                              <div>
                                <span class="text-light">{{ item2.time }}</span>
                              </div>
                            </v-btn>
                          </span>
                        </div>
                        <v-row>
                          <v-col sm="7" cols="12" class="pt-8">
                            <span
                              v-if="selectedTime.length"
                              class="h3 blue-grey--text text--darken-3"
                              >زمان انتخابی شما:
                              <span
                                @click="goToDate()"
                                class="selected-date pointer"
                                >{{ selectedDay.date }}</span
                              >
                              {{
                                " - " +
                                selectedDay.times[selectedTime[0]].time.split(
                                  "-"
                                )[0] +
                                " " +
                                "الی" +
                                " " +
                                selectedDay.times[
                                  selectedTime[selectedTime.length - 1]
                                ].time.split("-")[1]
                              }}</span
                            >
                          </v-col>
                          <v-col class="text-left">
                            <div v-if="schedules.length" class="text-left">
                              <v-btn
                                class="primary-btn mt-4"
                                :disabled="selectedTime.length == 0"
                                large
                                @click="opens = 1"
                              >
                                انتخاب بیمار
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </transition>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              style="border-radius: 15px; box-shadow: none"
              class="mt-5"
              readonly
            >
              <v-expansion-panel-header
                class="primary--text"
                style="font-size: 20px !important; cursor: default"
              >
                انتخاب بیمار
              </v-expansion-panel-header>
              <v-expansion-panel-content class="my-3 pa-5">
                <v-dialog v-model="newPatientDialog" persistent width="600px">
                  <v-card class="pa-3" style="border-radius: 20px">
                    <v-card-title>
                      <h3>ثبت بیمار جدید</h3>
                    </v-card-title>
                    <v-card-text>
                      <div>
                        <v-row class="time-row">
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <v-text-field
                              label="نام بیمار"
                              v-model="newPatient.firstName"
                              outlined
                              dense
                            ></v-text-field
                          ></v-col>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <v-text-field
                              label="نام‌خانوادگی بیمار"
                              v-model="newPatient.lastName"
                              outlined
                              dense
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="time-row">
                          <v-col class="pt-0" cols="12" md="6">
                            <v-select
                              label="جنسیت"
                              v-model="newPatient.gender"
                              :items="genders"
                              item-text="text"
                              item-value="value"
                              outlined
                              dense
                            ></v-select
                          ></v-col>
                          <v-col class="pt-0" cols="12" md="6">
                            <v-text-field
                              label="شماره موبایل"
                              v-model="newPatient.mobile"
                              type="number"
                              outlined
                              dense
                            ></v-text-field
                          ></v-col>
                        </v-row>

                        <v-row class="time-row">
                          <v-col class="pt-0" cols="12" md="6">
                            <v-text-field
                              label="کد ملی"
                              v-model="newPatient.natCode"
                              type="number"
                              :rules="[isID]"
                              outlined
                              dense
                            ></v-text-field
                          ></v-col>
                          <v-col class="pt-0" cols="12" md="6">
                            <v-text-field
                              label="شماره بیمه"
                              v-model="newPatient.insurNo"
                              outlined
                              dense
                              type="number"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="time-row">
                          <v-col class="pt-0">
                            <v-select
                              label="نوع بیمه"
                              v-model="newPatient.insurType"
                              :items="insurTypes"
                              item-text="text"
                              item-value="value"
                              outlined
                              dense
                            ></v-select
                          ></v-col>
                        </v-row>
                        <v-row class="time-row">
                          <v-col class="pt-0" cols="12" md="6">
                            <v-checkbox
                              v-model="newPatient.insuranceLastSheet"
                              class="lastSheet-checkBox"
                              color="primary"
                              label="تا آخرین صفحه دفترچه"
                            >
                            </v-checkbox>
                          </v-col>
                          <v-col class="pt-0" cols="12" md="6">
                            <v-text-field
                              outlined
                              dense
                              type="text"
                              append-icon="calendar_today"
                              v-model="newPatient.insuranceExpirationDate"
                              label="تاریخ انقضا دفترچه"
                              :editable="true"
                              class="date-input mt-2"
                              :disabled="newPatient.insuranceLastSheet"
                            >
                              <template #append>
                                <span id="addPatientinsuranceExpirationDate">
                                  <v-icon class="calender-icon"
                                    >mdi-calendar-blank</v-icon
                                  >
                                </span>
                              </template>
                            </v-text-field>

                            <date-picker
                              v-model="newPatient.insuranceExpirationDate"
                              element="addPatientinsuranceExpirationDate"
                              color="#00a7b7"
                          /></v-col>
                        </v-row>
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="red-btn"
                        @click="
                          newPatientDialog = false;
                          newPatient = {};
                        "
                      >
                        لغو
                      </v-btn>
                      <v-btn
                        class="primary-btn"
                        @click="submitNewPatient()"
                        :loading="addPatientBtnLoading"
                        :disabled="
                          newPatient.firstName == '' ||
                          newPatient.lastName == '' ||
                          newPatient.gender == '' ||
                          newPatient.mobile == '' ||
                          !checkNatCode(newPatient.natCode)
                        "
                      >
                        ثبت
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-row>
                  <v-col md="6" cols="12">
                    <h5 class="primary--text mb-4 text-right">فیلتر بیماران</h5>
                  </v-col>
                  <v-col md="6" cols="12">
                    <v-btn
                      @click="openNewPatient"
                      class="primary-btn mb-2"
                      style="float: left"
                    >
                      <v-icon left> person_add </v-icon>
                      افزودن بیمار
                    </v-btn></v-col
                  >
                </v-row>

                <v-row class="inputs-row">
                  <v-col md="3" cols="12">
                    <v-text-field
                      label="نام"
                      v-model="filterName"
                      class="search-input"
                      dense
                      outlined
                      @keyup.enter.native="
                        (filterName != '' ||
                          filterNatCode != '' ||
                          filterCode != '') &&
                        !Busy
                          ? filterPatients()
                          : ''
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      type="number"
                      label="کدملی"
                      no-wheel
                      class="search-input"
                      dense
                      outlined
                      v-model="filterNatCode"
                      @keyup.enter.native="
                        (filterName != '' ||
                          filterNatCode != '' ||
                          filterCode != '') &&
                        !Busy
                          ? filterPatients()
                          : ''
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      type="number"
                      label="کد اشتراک"
                      no-wheel
                      class="search-input"
                      dense
                      outlined
                      v-model="filterCode"
                      @keyup.enter.native="
                        (filterName != '' ||
                          filterNatCode != '' ||
                          filterCode != '') &&
                        !Busy
                          ? filterPatients()
                          : ''
                      "
                    ></v-text-field>
                  </v-col>
                  <v-btn
                    class="secondary-btn mb-1 ms-3 me-3"
                    @click="filterPatients()"
                    :disabled="
                      (filterName == '' &&
                        filterNatCode == '' &&
                        filterCode == '') ||
                      Busy
                    "
                  >
                    اعمال فیلتر
                    <v-icon right>tune</v-icon>
                  </v-btn>
                </v-row>

                <v-row>
                  <v-col cols="12" class="details-hidden">
                    <v-progress-circular
                      v-if="Busy"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <v-autocomplete
                      v-if="!Busy"
                      v-model="patientName"
                      :items="patientNames"
                      @change="patientVisitsNumber()"
                      item-text="text"
                      item-value="id"
                      class="search-input"
                      dense
                      outlined
                      label="نام بیمار"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <div
                  class="my-5"
                  v-if="
                    patientInfo.visitsNumbers >= 0 && patientName && !visitsBusy
                  "
                >
                  <hr />
                  <h5 class="primary--text mt-4">اطلاعات بیمار</h5>

                  <v-row class="mx-2">
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      lg="4"
                      xl="4"
                      class="text-right mt-3 px-0"
                    >
                      <div
                        class="w-100 border text-center px-5 py-3 rounded-lg"
                      >
                        <span class="primary--text pi-text font-weight-bold">
                          کد اشتراک:
                          {{
                            patientNames.filter((x) => x.id == patientName)[0]
                              .code
                          }}
                        </span>
                      </div></v-col
                    >
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      lg="3"
                      xl="3"
                      class="text-right mt-3"
                    >
                      <div
                        class="w-100 border text-center px-5 py-3 rounded-lg"
                      >
                        <span class="primary--text pi-text font-weight-bold">
                          تعداد مراجعه بیمار:
                          {{
                            patientInfo.visitsNumbers
                              ? patientInfo.visitsNumbers
                              : "-"
                          }}
                        </span>
                      </div></v-col
                    >
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      lg="3"
                      xl="3"
                      class="text-right mt-3 px-0"
                    >
                      <div
                        class="w-100 border text-center px-5 py-3 rounded-lg"
                        :class="
                          patientInfo.mobileStatus ? 'correct' : 'incorrect'
                        "
                      >
                        <span
                          class="primary--text pi-text font-weight-bold"
                          style="line-height: 20px"
                        >
                          شماره موبایل :
                          {{ patientInfo.mobile ? patientInfo.mobile : "-" }}
                        </span>
                      </div></v-col
                    >

                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      lg="2"
                      xl="2"
                      class="text-left pa-0 mt-6"
                    >
                      <v-btn
                        :class="
                          patientInfo.mobileStatus ? 'secondary-btn' : 'red-btn'
                        "
                        @click="
                          changeMobileDialog = true;
                          newMobile = patientInfo.mobile;
                        "
                        ><span>ویرایش موبایل</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <hr class="w-50 mt-8 mx-auto" />
                  <v-dialog v-model="changeMobileDialog" width="600">
                    <v-card class="pa-3 cards">
                      <v-card-title class="mb-5"
                        >ویرایش تلفن بیمار</v-card-title
                      >
                      <v-card-text>
                        <v-text-field
                          v-model="newMobile"
                          outlined
                          dense
                          label="شماره موبایل جدید"
                          type="number"
                          :placeholder="patientInfo.mobile"
                        >
                        </v-text-field>
                        <v-btn
                          class="primary-btn submit-btn pa-2 me-2 ms-2"
                          @click="changeMobileNumber()"
                        >
                          ثبت
                        </v-btn>
                        <v-btn
                          class="red-btn pa-2"
                          @click="changeMobileDialog = false"
                        >
                          لغو
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-dialog>

                  <v-row class="mt-5 mx-2">
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      lg="4"
                      xl="4"
                      class="text-right px-0"
                    >
                      <div
                        class="w-100 border text-center px-5 py-3 rounded-lg"
                      >
                        <span
                          class="primary--text pi-text font-weight-bold"
                          style="line-height: 20px"
                        >
                          نوع بیمه:
                          {{
                            patientInfo.insuranceId
                              ? insurTypes.filter(
                                  (x) => x.id == patientInfo.insuranceId
                                )[0].text
                              : "-"
                          }}
                        </span>
                      </div></v-col
                    >
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      lg="3"
                      xl="3"
                      class="text-right"
                    >
                      <div
                        class="w-100 border text-center px-5 py-3 rounded-lg"
                      >
                        <span class="primary--text pi-text font-weight-bold">
                          شماره بیمه :
                          {{ patientInfo.insurNo ? patientInfo.insurNo : "-" }}
                        </span>
                      </div></v-col
                    >
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      lg="3"
                      xl="3"
                      class="text-right px-0"
                    >
                      <div
                        class="w-100 border text-center px-5 py-3 rounded-lg"
                      >
                        <span
                          class="primary--text pi-text font-weight-bold"
                          style="line-height: 20px"
                        >
                          تاریخ انقضا دفترچه:
                          {{
                            patientInfo.insuranceLastSheet
                              ? "تا آخرین صفحه"
                              : patientInfo.insuranceExpirationDate
                              ? patientInfo.insuranceExpirationDate
                              : "-"
                          }}
                        </span>
                      </div></v-col
                    >
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                      lg="2"
                      xl="2"
                      class="text-left pa-0 mt-4"
                    >
                      <v-btn
                        class="secondary-btn"
                        @click="editInsuranceDialog = true"
                        :disabled="!patientName"
                        ><span>ویرایش بیمه</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <v-row>
                  <v-col></v-col>
                  <v-col class="text-left">
                    <div class="text-left">
                      <v-btn
                        class="purple-btn mt-4 ms-4"
                        large
                        @click="opens = 2"
                        :disabled="!patientName"
                      >
                        انتخاب خدمت
                      </v-btn>
                      <v-btn
                        class="purple-btn mt-4 ms-4"
                        large
                        @click="opens = 0"
                      >
                        تغییر زمان
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              style="border-radius: 15px; box-shadow: none"
              class="mt-5"
              readonly
            >
              <v-expansion-panel-header
                class="primary--text"
                style="font-size: 20px !important; cursor: default"
              >
                انتخاب خدمت
              </v-expansion-panel-header>
              <v-expansion-panel-content :class="deviceType == 'mobile' ? 'px-3' : 'px-5'">
                <v-row class="mt-0 mb-2 mt-4">
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span id="dateDate">
                      <v-text-field
                        outlined
                        dense
                        type="text"
                        append-icon="calendar_today"
                        v-model="dateDate"
                        label=" تاریخ  "
                        :editable="true"
                        class="date-input"
                        disabled
                        hide-details
                      >
                      </v-text-field>
                    </span>
                    <!-- تاریخ و ساعت در تعریف درخواست برای پذیرش بسته است  -->
                    <!-- <date-picker
                  v-model="dateDate"
                  readonly
                  disabled
                  element="dateDate"
                  color="#00a7b7"
                /> -->
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <span id="dateTime">
                      <v-text-field
                        outlined
                        dense
                        append-icon="schedule"
                        v-model="dateTime"
                        label=" ساعت "
                        :editable="true"
                        class="date-input"
                        disabled
                        hide-details
                      >
                      </v-text-field>
                    </span>
                    <!-- تاریخ و ساعت در تعریف درخواست برای پذیرش بسته است  -->
                    <!-- <date-picker
                  v-model="dateTime"
                  readonly
                  element="dateTime"
                  color="#00a7b7"
                  type="time"
                /> -->
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="visitType"
                      :items="requestTypes"
                      item-text="text"
                      item-value="value"
                      label="نوع خدمت"
                      class="w-100"
                      menu-props="auto"
                      hide-details
                      outlined
                      dense
                      @change="clinicServices()"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    v-if="
                      patientInfo.insuranceId
                        ? insurTypes.filter(
                            (x) => x.code == patientInfo.insuranceId
                          )[0].text != 'آزاد' &&
                          insurTypes.filter(
                            (x) => x.code == patientInfo.insuranceId
                          )[0].text != 'بیرونی'
                        : ''
                    "
                  >
                    <v-text-field
                      outlined
                      dense
                      v-model="insurancePageNo"
                      label="شماره صفحه دفترچه"
                      :editable="true"
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="mb-3" v-if="visitType">
                  <div class="mb-3" v-if="visitType == 'public'">
                    <v-card>
                      <v-card-title class="ps-0 pe-0">
                        <h5 class="mt-4">
                          جدول خدمات درمانگاه عمومی
                        </h5></v-card-title
                      >
                      <v-card-text class="ps-0 pe-0">
                        <div>
                          <v-text-field
                            v-model="serviceFilter"
                            prepend-inner-icon="mdi-magnify"
                            label="جستجو"
                            single-line
                            hide-details
                            clearable
                            rounded
                            filled
                            class="text-right w-80 mb-2 search-input"
                          ></v-text-field>

                          <div>
                            <!-- here -->
                            <v-btn
                              @click="
                                newServiceDialog = true;
                                newService = {};
                              "
                              class="primary-btn my-5"
                              v-if="deviceType == 'mobile'"
                              >افزودن خدمت</v-btn
                            >
                            <b-table
                              v-else
                              responsive
                              small
                              show-empty
                              :items="freeServiceItems"
                              :fields="freeServiceFields"
                              empty-text="رکوردی برای نمایش وجود ندارد"
                            >
                              <template v-slot:head()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.label }}
                                </div>
                              </template>
                              <template v-slot:cell()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.value }}
                                </div>
                              </template>
                              <template v-slot:cell(operation)="data">
                                <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  <v-btn
                                    class="primary-btn pa-3"
                                    @click="addFreeService(data.item)"
                                    :disabled="
                                      !data.item.name ||
                                      !data.item.cost ||
                                      !data.item.type
                                    "
                                    >افزودن</v-btn
                                  >
                                </div>
                              </template>
                              <template v-slot:cell(cost)="data">
                                <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                                <div
                                  style="text-align: center; vertical-align: middle; width 150px"
                                >
                                  <vuetify-money
                                    v-model="data.item.cost"
                                    placeholder="قیمت"
                                    :valueWhenIsEmpty="whenIsEmpty"
                                    :options="vMoneyOptions"
                                    type="number"
                                    outlined
                                    dense
                                    class="mx-auto my-1"
                                  />
                                </div>
                              </template>
                              <template v-slot:cell(clinicShare)="data">
                                <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                                <div
                                  style="text-align: center; vertical-align: middle; width 150px"
                                >
                                  <vuetify-money
                                    v-model="data.item.clinicShare"
                                    placeholder="هزینه"
                                    :valueWhenIsEmpty="whenIsEmpty"
                                    :options="vMoneyOptions"
                                    type="number"
                                    outlined
                                    dense
                                    class="mx-auto my-1"
                                  />
                                </div>
                              </template>
                              <template v-slot:cell(expense)="data">
                                <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                                <div
                                  style="text-align: center; vertical-align: middle; width 150px"
                                >
                                  <vuetify-money
                                    v-model="data.item.expense"
                                    placeholder="هزینه"
                                    :valueWhenIsEmpty="whenIsEmpty"
                                    :options="vMoneyOptions"
                                    type="number"
                                    outlined
                                    dense
                                    class="mx-auto my-1"
                                  />
                                </div>
                              </template>
                              <template v-slot:cell(type)="data">
                                <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  <v-select
                                    :items="[
                                      'خدمات پرستاری',
                                      'ویزیت',
                                      'خدمات پزشک',
                                      'اقلام مصرفی',
                                    ]"
                                    v-model="data.item.type"
                                    class="mx-auto my-1"
                                    outlined
                                    dense
                                    placeholder="انتخاب کنید"
                                  />
                                </div>
                              </template>

                              <template v-slot:cell(name)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                    width: 100%;
                                  "
                                >
                                  <v-autocomplete
                                    v-model="data.item.name"
                                    :items="freeNames"
                                    item-text="text"
                                    item-value="id"
                                    class="mx-auto my-1"
                                    outlined
                                    dense
                                    placeholder="انتخاب کنید"
                                  ></v-autocomplete>
                                </div>
                              </template>
                              <template v-slot:cell(patientCost)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.item.cost }}
                                </div>
                              </template>
                            </b-table>

                            <!-- here -->
                            <b-table
                              responsive
                              show-empty
                              empty-text="خدمتی برای نمایش وجود ندارد"
                              empty-filtered-text="خدمتی برای نمایش وجود ندارد"
                              :fields="computedServiceFields"
                              :items="serviceItems"
                              :current-page="serviceCurrentPage"
                              :per-page="servicePerPage"
                              :busy="serviceBusy"
                              :filter="serviceFilter"
                              @filtered="serviceonFiltered"
                            >
                              <template v-slot:head()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.label }}
                                </div>
                              </template>
                              <template v-slot:cell()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.value }}
                                </div>
                              </template>
                              <template v-slot:cell(index)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{
                                    data.index +
                                    servicePerPage * (serviceCurrentPage - 1) +
                                    1
                                  }}
                                </div>
                              </template>
                              <template v-slot:cell(patientCost)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ Number(data.value).toLocaleString() }}
                                </div>
                              </template>
                              <template v-slot:cell(clinicShare)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ Number(data.value).toLocaleString() }}
                                </div>
                              </template>
                              <template v-slot:cell(cost)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ Number(data.value).toLocaleString() }}
                                </div>
                              </template>
                              <template v-slot:cell(operation)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  <v-btn
                                    class="primary-btn pa-3"
                                    @click="addtoNewService(data.item)"
                                  >
                                    افزودن
                                  </v-btn>
                                </div>
                              </template>
                              <div
                                slot="table-busy"
                                class="text-center primary--text my-2"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                  class="align-middle"
                                ></v-progress-circular>
                              </div>
                            </b-table>
                            <v-pagination
                              v-model="serviceCurrentPage"
                              :length="
                                Math.ceil(serviceTotalRows / servicePerPage)
                              "
                              :total-visible="5"
                              prev-icon="arrow_back"
                              next-icon="arrow_forward"
                            ></v-pagination>
                          </div>

                          <hr />

                          <h3 class="mt-3 mb-3">
                            <strong>خدمات درخواستی</strong>
                          </h3>

                          <b-table
                            :fields="computedServiceFields"
                            :items="newItems"
                            :current-page="newCurrentPage"
                            :per-page="newPerPage"
                            :filter="newFilter"
                            @filtered="onFiltered"
                            responsive
                          >
                            <template v-slot:head()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.label }}
                              </div>
                            </template>
                            <template v-slot:cell()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.value }}
                              </div>
                            </template>
                            <template v-slot:cell(index)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.index +
                                  newPerPage * (newCurrentPage - 1) +
                                  1
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(clinicShare)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(description)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.item.description.substr(0, 100) +
                                  (data.item.description.length > 100
                                    ? "..."
                                    : "")
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(patientCost)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(cost)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(operation)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                <v-btn
                                  outlined
                                  @click="delService(data.index)"
                                  class="pa-2 red-btn"
                                  ><v-icon>remove</v-icon></v-btn
                                >
                              </div>
                            </template>
                            <div
                              slot="table-busy"
                              class="text-center primary--text my-2"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                class="align-middle"
                              ></v-progress-circular>
                            </div>
                          </b-table>
                          <v-pagination
                            v-model="newCurrentPage"
                            :length="Math.ceil(newTotalRows / newPerPage)"
                            :total-visible="5"
                            prev-icon="arrow_back"
                            next-icon="arrow_forward"
                          ></v-pagination>
                          <hr />
                          <h5 class="text-black mb-3 ms-3 me-3">
                            <strong
                              >قیمت کل:
                              {{
                                newItems
                                  .map((x) => x.patientCost)
                                  .reduce(
                                    (a, b) => parseInt(a) + parseInt(b),
                                    0
                                  )
                                  .toLocaleString()
                              }} </strong
                            >ریال
                          </h5>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="mb-3" v-if="visitType == 'expert'">
                    <v-card>
                      <v-card-title class="ps-0 pe-0"
                        ><h5 class="mt-4">
                          جدول خدمات درمانگاه تخصصی
                        </h5></v-card-title
                      >
                      <v-card-text class="ps-0 pe-0">
                        <div>
                          <v-text-field
                            v-model="serviceFilter"
                            prepend-inner-icon="mdi-magnify"
                            label="جستجو"
                            single-line
                            hide-details
                            clearable
                            filled
                            rounded
                            class="text-right w-80 mb-2 search-input"
                          ></v-text-field>

                          <div>
                            <!-- here -->
                            <v-btn
                              @click="
                                newServiceDialog = true;
                                newService = {};
                              "
                              class="primary-btn my-5"
                              v-if="deviceType == 'mobile'"
                              >افزودن خدمت</v-btn
                            >
                            <b-table
                              v-else
                              responsive
                              small
                              show-empty
                              :items="freeServiceItems"
                              :fields="freeServiceFields"
                              empty-text="رکوردی برای نمایش وجود ندارد"
                            >
                              <template v-slot:head()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.label }}
                                </div>
                              </template>
                              <template v-slot:cell()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.value }}
                                </div>
                              </template>
                              <template v-slot:cell(operation)="data">
                                <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  <v-btn
                                    class="primary-btn pa-3"
                                    @click="addFreeService(data.item)"
                                    :disabled="
                                      !data.item.name ||
                                      !data.item.cost ||
                                      !data.item.type
                                    "
                                    >افزودن</v-btn
                                  >
                                </div>
                              </template>
                              <template v-slot:cell(cost)="data">
                                <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                                <div
                                  style="text-align: center; vertical-align: middle; width 150px"
                                >
                                  <vuetify-money
                                    v-model="data.item.cost"
                                    placeholder="قیمت"
                                    :valueWhenIsEmpty="whenIsEmpty"
                                    :options="vMoneyOptions"
                                    type="number"
                                    outlined
                                    dense
                                    class="mx-auto my-1"
                                  />
                                </div>
                              </template>
                              <template v-slot:cell(clinicShare)="data">
                                <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                                <div
                                  style="text-align: center; vertical-align: middle; width 150px"
                                >
                                  <vuetify-money
                                    v-model="data.item.clinicShare"
                                    placeholder="هزینه"
                                    :valueWhenIsEmpty="whenIsEmpty"
                                    :options="vMoneyOptions"
                                    type="number"
                                    outlined
                                    dense
                                    class="mx-auto my-1"
                                  />
                                </div>
                              </template>
                              <template v-slot:cell(expense)="data">
                                <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                                <div
                                  style="text-align: center; vertical-align: middle; width 150px"
                                >
                                  <vuetify-money
                                    v-model="data.item.expense"
                                    placeholder="هزینه"
                                    :valueWhenIsEmpty="whenIsEmpty"
                                    :options="vMoneyOptions"
                                    type="number"
                                    outlined
                                    dense
                                    class="mx-auto my-1"
                                  />
                                </div>
                              </template>
                              <template v-slot:cell(type)="data">
                                <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  <v-select
                                    :items="['ویزیت تخصصی', 'خدمات تخصصی']"
                                    v-model="data.item.type"
                                    class="mx-auto my-1"
                                    outlined
                                    dense
                                    placeholder="انتخاب کنید"
                                  />
                                </div>
                              </template>

                              <template v-slot:cell(name)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                    width: 100%;
                                  "
                                >
                                  <v-autocomplete
                                    v-model="data.item.name"
                                    :items="freeNames"
                                    item-text="text"
                                    item-value="id"
                                    class="mx-auto my-1"
                                    style="z-index: 20000"
                                    outlined
                                    dense
                                    placeholder="انتخاب کنید"
                                  ></v-autocomplete>
                                </div>
                              </template>
                              <template v-slot:cell(patientCost)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.item.cost }}
                                </div>
                              </template>
                            </b-table>

                            <!-- here -->
                            <b-table
                              responsive
                              show-empty
                              empty-text="خدمتی برای نمایش وجود ندارد"
                              empty-filtered-text="خدمتی برای نمایش وجود ندارد"
                              :fields="computedServiceFields"
                              :items="serviceItems"
                              :current-page="serviceCurrentPage"
                              :per-page="servicePerPage"
                              :busy="serviceBusy"
                              :filter="serviceFilter"
                              @filtered="serviceonFiltered"
                            >
                              <template v-slot:head()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.label }}
                                </div>
                              </template>
                              <template v-slot:cell()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.value }}
                                </div>
                              </template>
                              <template v-slot:cell(index)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{
                                    data.index +
                                    servicePerPage * (serviceCurrentPage - 1) +
                                    1
                                  }}
                                </div>
                              </template>
                              <template v-slot:cell(patientCost)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ Number(data.value).toLocaleString() }}
                                </div>
                              </template>
                              <template v-slot:cell(clinicShare)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ Number(data.value).toLocaleString() }}
                                </div>
                              </template>
                              <template v-slot:cell(cost)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ Number(data.value).toLocaleString() }}
                                </div>
                              </template>
                              <template v-slot:cell(operation)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  <v-btn
                                    class="primary-btn pa-3"
                                    @click="addtoNewService(data.item)"
                                  >
                                    افزودن
                                  </v-btn>
                                </div>
                              </template>
                              <div
                                slot="table-busy"
                                class="text-center primary--text my-2"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                  class="align-middle"
                                ></v-progress-circular>
                              </div>
                            </b-table>
                            <v-pagination
                              v-model="serviceCurrentPage"
                              :length="
                                Math.ceil(serviceTotalRows / servicePerPage)
                              "
                              :total-visible="5"
                              prev-icon="arrow_back"
                              next-icon="arrow_forward"
                            ></v-pagination>
                          </div>

                          <hr />

                          <h5 class="mb-3">
                            <strong>خدمات درخواستی</strong>
                          </h5>

                          <b-table
                            :fields="computedServiceFields"
                            :items="newItems"
                            :current-page="newCurrentPage"
                            :per-page="newPerPage"
                            :filter="newFilter"
                            @filtered="onFiltered"
                            responsive
                          >
                            <template v-slot:head()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.label }}
                              </div>
                            </template>
                            <template v-slot:cell()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.value }}
                              </div>
                            </template>
                            <template v-slot:cell(index)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.index +
                                  newPerPage * (newCurrentPage - 1) +
                                  1
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(clinicShare)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(description)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.item.description.substr(0, 100) +
                                  (data.item.description.length > 100
                                    ? "..."
                                    : "")
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(patientCost)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(cost)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(operation)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                <v-btn
                                  outlined
                                  @click="delService(data.index)"
                                  class="pa-2 red-btn"
                                  ><v-icon>remove</v-icon></v-btn
                                >
                              </div>
                            </template>
                            <div
                              slot="table-busy"
                              class="text-center primary--text my-2"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                class="align-middle"
                              ></v-progress-circular>
                            </div>
                          </b-table>
                          <v-pagination
                            v-model="newCurrentPage"
                            :length="Math.ceil(newTotalRows / newPerPage)"
                            :total-visible="5"
                            prev-icon="arrow_back"
                            next-icon="arrow_forward"
                          ></v-pagination>
                          <hr />
                          <h5 class="text-black mb-3 ms-3 me-3">
                            <strong
                              >قیمت کل:
                              {{
                                newItems
                                  .map((x) => x.patientCost)
                                  .reduce(
                                    (a, b) => parseInt(a) + parseInt(b),
                                    0
                                  )
                                  .toLocaleString()
                              }} </strong
                            >ریال
                          </h5>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                  <!-- NOTE  dental-->
                  <div class="mb-3" v-if="visitType == 'دندانپزشکی'">
                    <v-card>
                      <v-card-title class="ps-0 pe-0">
                        <h5 class="mt-4">
                          جدول خدمات و افزودن خدمات مورد نیاز
                        </h5>
                      </v-card-title>
                      <v-card-text class="ps-0 pe-0">
                        <div>
                          <v-text-field
                            v-model="dentistServiceFilter"
                            prepend-inner-icon="mdi-magnify"
                            label="جستجو"
                            single-line
                            hide-details
                            clearable
                            rounded
                            filled
                            class="text-right w-80 mb-2 search-input"
                          ></v-text-field>

                          <b-table
                            responsive
                            small
                            show-empty
                            :items="dentistFreeServiceItems"
                            :fields="dentistFreeServiceFields"
                            empty-text="رکوردی برای نمایش وجود ندارد"
                          >
                            <template v-slot:head()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.label }}
                              </div>
                            </template>
                            <template v-slot:cell()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.value }}
                              </div>
                            </template>
                            <template v-slot:cell(operation)="data">
                              <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                <v-btn
                                  class="primary-btn pa-3"
                                  @click="addFreeDentistService(data.item)"
                                  :disabled="!data.item.name || !data.item.cost"
                                  >افزودن</v-btn
                                >
                              </div>
                            </template>
                            <template v-slot:cell(cost)="data">
                              <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                              <div
                                style="text-align: center; vertical-align: middle; width 150px"
                              >
                                <vuetify-money
                                  v-model="data.item.cost"
                                  placeholder="قیمت"
                                  :valueWhenIsEmpty="whenIsEmpty"
                                  :options="vMoneyOptions"
                                  type="number"
                                  outlined
                                  dense
                                  class="mx-auto my-1"
                                />
                              </div>
                            </template>
                            <template v-slot:cell(clinicShare)="data">
                              <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                              <div
                                style="text-align: center; vertical-align: middle; width 150px"
                              >
                                <vuetify-money
                                  v-model="data.item.clinicShare"
                                  placeholder="هزینه"
                                  :valueWhenIsEmpty="whenIsEmpty"
                                  :options="vMoneyOptions"
                                  type="number"
                                  outlined
                                  dense
                                  class="mx-auto my-1"
                                />
                              </div>
                            </template>
                            <template v-slot:cell(expense)="data">
                              <!-- NOTE (b-delAvhievment) to remove a row from achievment items table -->
                              <div
                                style="text-align: center; vertical-align: middle; width 150px"
                              >
                                <vuetify-money
                                  v-model="data.item.expense"
                                  placeholder="هزینه"
                                  :valueWhenIsEmpty="whenIsEmpty"
                                  :options="vMoneyOptions"
                                  type="number"
                                  outlined
                                  dense
                                  class="mx-auto my-1"
                                />
                              </div>
                            </template>

                            <template v-slot:cell(name)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                  width: 100%;
                                "
                              >
                                <v-autocomplete
                                  v-model="data.item.name"
                                  :items="dentistFreeNames"
                                  item-text="text"
                                  item-value="id"
                                  class="mx-auto my-1"
                                  outlined
                                  dense
                                  placeholder="انتخاب کنید"
                                ></v-autocomplete>
                              </div>
                            </template>
                            <template v-slot:cell(patientCost)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.item.cost }}
                              </div>
                            </template>
                          </b-table>

                          <b-table
                            responsive
                            show-empty
                            empty-text="خدمتی برای نمایش وجود ندارد"
                            empty-filtered-text="خدمتی برای نمایش وجود ندارد"
                            :fields="dentistServiceFields"
                            :items="dentistItems"
                            :current-page="dentistServiceCurrentPage"
                            :per-page="dentistServicePerPage"
                            :busy="serviceBusy"
                            :filter="dentistServiceFilter"
                            @filtered="dentistServiceonFiltered"
                          >
                            <template v-slot:head()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.label }}
                              </div>
                            </template>
                            <template v-slot:cell()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.value }}
                              </div>
                            </template>
                            <template v-slot:cell(index)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.index +
                                  dentistServicePerPage *
                                    (dentistServiceCurrentPage - 1) +
                                  1
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(patientCost)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(clinicShare)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(cost)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <!-- NOTE dokme -->
                            <template v-slot:cell(operation)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                <v-btn
                                  class="primary-btn pa-3"
                                  @click="addtoNewDentistService(data.item)"
                                >
                                  افزودن
                                </v-btn>
                              </div>
                            </template>
                            <!-- NOTE DOKME -->
                            <div
                              slot="table-busy"
                              class="text-center primary--text my-2"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                class="align-middle"
                              ></v-progress-circular>
                            </div>
                          </b-table>
                          <v-pagination
                            v-model="dentistServiceCurrentPage"
                            :length="
                              Math.ceil(
                                dentistTotalRows / dentistServicePerPage
                              )
                            "
                            :total-visible="5"
                            prev-icon="arrow_back"
                            next-icon="arrow_forward"
                          ></v-pagination>

                          <hr />

                          <h5 class="mb-3">
                            <strong>خدمات درخواستی</strong>
                          </h5>

                          <b-table
                            :fields="dentistServiceFields"
                            :items="dentistNewItems"
                            :current-page="dentistNewCurrentPage"
                            :per-page="dentistNewPerPage"
                            :filter="dentistNewFilter"
                            @filtered="dentistonFiltered"
                            responsive
                          >
                            <template v-slot:head()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.label }}
                              </div>
                            </template>
                            <template v-slot:cell()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.value }}
                              </div>
                            </template>
                            <template v-slot:cell(index)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.index +
                                  dentistNewPerPage *
                                    (dentistNewCurrentPage - 1) +
                                  1
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(clinicShare)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(description)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.item.description.substr(0, 100) +
                                  (data.item.description.length > 100
                                    ? "..."
                                    : "")
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(patientCost)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(cost)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(operation)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                <v-btn
                                  outlined
                                  @click="dentistDelService(data.index)"
                                  class="pa-2 red-btn"
                                  ><v-icon>remove</v-icon></v-btn
                                >
                              </div>
                            </template>
                            <div
                              slot="table-busy"
                              class="text-center primary--text my-2"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                class="align-middle"
                              ></v-progress-circular>
                            </div>
                          </b-table>
                          <v-pagination
                            v-model="dentistNewCurrentPage"
                            :length="
                              Math.ceil(dentistNewTotalRows / dentistNewPerPage)
                            "
                            :total-visible="5"
                            prev-icon="arrow_back"
                            next-icon="arrow_forward"
                          ></v-pagination>
                          <hr />
                          <h5 class="text-black mb-3 ms-3 me-3">
                            <strong
                              >قیمت کل:
                              {{
                                dentistNewItems
                                  .map((x) => x.patientCost)
                                  .reduce(
                                    (a, b) => parseInt(a) + parseInt(b),
                                    0
                                  )
                                  .toLocaleString()
                              }} </strong
                            >ریال
                          </h5>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                  <!-- NOTE dental -->

                  <!-- NOTE paraclinic -->
                  <div class="mb-3" v-if="visitType == 'پاراکلینیک'">
                    <v-card>
                      <v-card-title>
                        جدول خدمات و افزودن خدمات مورد نیاز
                      </v-card-title>
                      <v-card-text>
                        <div>
                          <v-text-field
                            v-model="serviceFilter"
                            prepend-inner-icon="mdi-magnify"
                            label="جستجو"
                            single-line
                            hide-details
                            clearable
                            filled
                            rounded
                            class="text-right w-80 mb-2 search-input"
                          ></v-text-field>
                          <div v-if="visitType == 'پارکلینیک'">
                            <b-table
                              responsive
                              show-empty
                              empty-text="خدمتی برای نمایش وجود ندارد"
                              empty-filtered-text="خدمتی برای نمایش وجود ندارد"
                              :fields="computedServiceFields"
                              :items="serviceItems"
                              :current-page="serviceCurrentPage"
                              :per-page="servicePerPage"
                              :busy="serviceBusy"
                              :filter="serviceFilter"
                              @filtered="serviceonFiltered"
                            >
                              <template v-slot:head()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.label }}
                                </div>
                              </template>
                              <template v-slot:cell()="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ data.value }}
                                </div>
                              </template>
                              <template v-slot:cell(index)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{
                                    data.index +
                                    servicePerPage * (serviceCurrentPage - 1) +
                                    1
                                  }}
                                </div>
                              </template>
                              <template v-slot:cell(patientCost)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ Number(data.value).toLocaleString() }}
                                </div>
                              </template>
                              <template v-slot:cell(clinicShare)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ Number(data.value).toLocaleString() }}
                                </div>
                              </template>
                              <template v-slot:cell(cost)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  {{ Number(data.value).toLocaleString() }}
                                </div>
                              </template>
                              <template v-slot:cell(operation)="data">
                                <div
                                  style="
                                    text-align: center;
                                    vertical-align: middle;
                                  "
                                >
                                  <v-btn
                                    class="primary-btn"
                                    @click="addtoNewService(data.item)"
                                  >
                                  </v-btn>
                                </div>
                              </template>
                              <div
                                slot="table-busy"
                                class="text-center primary--text my-2"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                  class="align-middle"
                                ></v-progress-circular>
                              </div>
                            </b-table>
                            <v-pagination
                              v-model="serviceCurrentPage"
                              :length="
                                Math.ceil(serviceTotalRows / servicePerPage)
                              "
                              :total-visible="5"
                              prev-icon="arrow_back"
                              next-icon="arrow_forward"
                              style="float: center"
                            ></v-pagination>
                          </div>

                          <hr />

                          <h5 class="mb-3">
                            <strong>خدمات درخواستی</strong>
                          </h5>

                          <b-table
                            :fields="computedServiceFields"
                            :items="newItems"
                            :current-page="newCurrentPage"
                            :per-page="newPerPage"
                            :filter="newFilter"
                            @filtered="onFiltered"
                            responsive
                          >
                            <template v-slot:head()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.label }}
                              </div>
                            </template>
                            <template v-slot:cell()="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ data.value }}
                              </div>
                            </template>
                            <template v-slot:cell(index)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.index +
                                  newPerPage * (newCurrentPage - 1) +
                                  1
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(clinicShare)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(description)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{
                                  data.item.description.substr(0, 100) +
                                  (data.item.description.length > 100
                                    ? "..."
                                    : "")
                                }}
                              </div>
                            </template>
                            <template v-slot:cell(patientCost)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(cost)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                {{ Number(data.value).toLocaleString() }}
                              </div>
                            </template>
                            <template v-slot:cell(operation)="data">
                              <div
                                style="
                                  text-align: center;
                                  vertical-align: middle;
                                "
                              >
                                <v-btn
                                  outlined
                                  class="red-btn pa-2"
                                  @click="delService(data.index)"
                                  ><v-icon>remove</v-icon></v-btn
                                >
                              </div>
                            </template>
                            <div
                              slot="table-busy"
                              class="text-center primary--text my-2"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                                class="align-middle"
                              ></v-progress-circular>
                            </div>
                          </b-table>
                          <v-pagination
                            v-model="newCurrentPage"
                            :length="Math.ceil(newTotalRows / newPerPage)"
                            :total-visible="5"
                            prev-icon="arrow_back"
                            next-icon="arrow_forward"
                            style="float: center"
                          ></v-pagination>
                          <hr />
                          <h5 class="text-black mb-3 ms-3 me-3">
                            <strong
                              >قیمت کل:
                              {{
                                newItems
                                  .map((x) => x.patientCost)
                                  .reduce(
                                    (a, b) => parseInt(a) + parseInt(b),
                                    0
                                  )
                                  .toLocaleString()
                              }} </strong
                            >ریال
                          </h5>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                  <!-- NOTE paraclicnic -->
                  <div v-else-if="visitType == 'مشاوره'">
                    <hr class="mb-2 mt-4" />
                    <v-row class="mt-3">
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <vuetify-money
                          v-model="price"
                          label="هزینه(﷼)"
                          :valueWhenIsEmpty="whenIsEmpty"
                          :options="vMoneyOptions"
                          type="number"
                          outlined
                          dense
                          style=""
                        />
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <v-row class="mb-3">
                  <v-col class="text-right">
                    <v-btn
                      class="green-btn mt-4"
                      :disabled="computedDisabled"
                      large
                      @click="requestDialog = true"
                    >
                      <v-icon class="me-1">mdi-calendar-check-outline</v-icon>
                      ثبت وقت ملاقات
                    </v-btn>
                  </v-col>

                  <v-col class="text-left">
                    <v-btn class="primary-btn mt-4" large @click="opens = 0">
                      تغییر زمان
                    </v-btn>
                    <v-btn
                      class="primary-btn mt-4 ms-2"
                      large
                      @click="opens = 1"
                    >
                      تغییر بیمار
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </div>
    </v-col>

    <v-dialog v-model="requestDialog" width="500px">
      <v-card class="pa-5 cards" style="border-radius: 20px">
        <v-card-title>
          <span class="h1"> تایید درخواست </span>
        </v-card-title>
        <hr class="mt-0 mb-6" />
        <v-card-text class="text-center mt-5 px-10">
          <v-row>
            <v-col class="py-2">
              <span class="h2 primary--text">
                <v-icon class="me-1 primary--text">mdi-doctor</v-icon>
                نام پزشک :</span
              >
              <span class="h2">
                {{
                  drNames.filter((x) => x.value == doctorId)[0]
                    ? drNames.filter((x) => x.value == doctorId)[0].nameW
                    : ""
                }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-2">
              <span class="h2 primary--text">
                <v-icon class="me-1 primary--text">mdi-account-outline</v-icon>
                نام بیمار :</span
              >
              <span class="h2">
                {{
                  patientNames.filter((x) => x.id == patientName)[0]
                    ? patientNames.filter((x) => x.id == patientName)[0].nameW
                    : "-"
                }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-2">
              <span class="h2 primary--text">
                <v-icon class="me-1 primary--text">mdi-calendar-month</v-icon>
                تاریخ :</span
              >
              <span v-if="selectedDay.date" class="h2">{{
                " " + selectedDay.date
              }}</span>
              <span v-else>-</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-2">
              <span class="h2 primary--text">
                <v-icon class="me-1 primary--text">mdi-calendar-clock</v-icon>
                {{ selectedTime.length == 1 ? " ساعت" : " ساعت‌ها" }}:</span
              >
              <span class="h2">
                <span v-if="selectedTime.length"
                  ><span>{{
                    " " +
                    selectedDay.times[selectedTime[0]].time.split("-")[0] +
                    "-" +
                    selectedDay.times[
                      selectedTime[selectedTime.length - 1]
                    ].time.split("-")[1]
                  }}</span></span
                >
                <span v-else>-</span></span
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="primary-btn px-3 py-5 ms-auto"
            width="120"
            @click="submit()"
          >
            <v-icon class="me-1">mdi-check-circle-outline</v-icon>
            تایید
          </v-btn>
          <v-btn
            class="red-btn px-3 py-5"
            @click="requestDialog = false"
            width="120"
          >
            <v-icon class="me-1">mdi-close-circle-outline</v-icon>
            انصراف
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="colorsGuidance" width="500px">
      <v-card class="pa-3 cards" style="border-radius: 20px">
        <v-card-title>
          <span class="mx-auto c-primary"> راهنمای رنگ‌ها </span>
        </v-card-title>
        <v-container class="text-right">
          <v-row class="px-5">
            <v-col>
              <ul class="h2">
                <li class="green--text text--lighten-1 pb-3">زمان‌ آزاد</li>
                <li class="red--text text--darken-1 pb-3">
                  زمان ملاقات ثبت شده
                </li>
                <li class="yellow--text text--darken-3 pb-3">
                  زمان انتخاب شده
                </li>
                <li class="grey--text">زمان گذشته</li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editInsuranceDialog" width="600">
      <v-card class="pa-3 cards">
        <v-card-title class="mb-5">ویرایش اطلاعات بیمه</v-card-title>
        <v-card-text>
          <v-select
            label="نوع بیمه"
            v-model="insuranceInfo.insuranceId"
            :items="insurTypes"
            item-text="text"
            item-value="value"
            outlined
            dense
          ></v-select>

          <div
            v-if="
              insuranceInfo.insuranceId
                ? insurTypes.filter(
                    (x) => x.code == insuranceInfo.insuranceId
                  )[0].text != 'آزاد' &&
                  insurTypes.filter(
                    (x) => x.code == insuranceInfo.insuranceId
                  )[0].text != 'بیرونی'
                : ''
            "
          >
            <v-checkbox
              v-model="insuranceInfo.insuranceLastSheet"
              class="lastSheet-checkBox mt-0"
              color="primary"
              label="تا آخرین صفحه دفترچه"
            >
            </v-checkbox>
            <v-text-field
              v-model="insuranceInfo.insurNo"
              outlined
              dense
              label="شماره بیمه"
              type="number"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              type="text"
              append-icon="calendar_today"
              v-model="insuranceInfo.insuranceExpirationDate"
              label="تاریخ انقضا دفترچه"
              :editable="true"
              class="date-input mt-2"
              :disabled="insuranceInfo.insuranceLastSheet"
            >
              <template #append>
                <span id="insuranceExpirationDateEdit">
                  <v-icon class="calender-icon">mdi-calendar-blank</v-icon>
                </span>
              </template>
            </v-text-field>
            <date-picker
              v-model="insuranceInfo.insuranceExpirationDate"
              element="insuranceExpirationDateEdit"
              color="#00a7b7"
              :disabled="insuranceInfo.insuranceLastSheet"
            />
          </div>

          <v-btn
            class="primary-btn submit-btn pa-2 me-2 ms-2"
            @click="editPatientInsurance()"
            :disabled="
              insuranceInfo.insuranceId
                ? insurTypes.filter(
                    (x) => x.code == insuranceInfo.insuranceId
                  )[0].text != 'آزاد' &&
                  insurTypes.filter(
                    (x) => x.code == insuranceInfo.insuranceId
                  )[0].text != 'بیرونی'
                  ? !insuranceInfo.insuranceId ||
                    !insuranceInfo.insurNo ||
                    (!insuranceInfo.insuranceLastSheet &&
                      !insuranceInfo.insuranceExpirationDate)
                  : false
                : true
            "
          >
            ثبت
          </v-btn>
          <v-btn class="red-btn pa-2" @click="editInsuranceDialog = false">
            لغو
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addFreeDialog" width="600px">
      <v-card style="border-radius: 20px" class="px-5 py-3">
        <v-card-title class="ps-2">
          <span class="h1"> افزودن زمان آزاد</span>
        </v-card-title>
        <v-card-text class="p-0">
          <v-container>
            <v-row>
              <v-col md="6" cols="12">
                <span id="freeDateFrom">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="freeDateFrom"
                    :editable="true"
                    class="date-input"
                    label="تاریخ از"
                  >
                  </v-text-field>
                </span>
                <date-picker
                  v-model="freeDateFrom"
                  element="freeDateFrom"
                  color="#00a7b7"
                  :min="currentDate"
                />
              </v-col>
              <v-col md="6" cols="12">
                <span id="freeDateTo">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="freeDateTo"
                    :editable="true"
                    class="date-input"
                    label="تاریخ تا"
                  >
                  </v-text-field>
                </span>
                <date-picker
                  v-model="freeDateTo"
                  element="freeDateTo"
                  color="#00a7b7"
                  :min="currentDate"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <span id="freeTimeFrom">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="freeTimeFrom"
                    :editable="true"
                    class="date-input"
                    label="ساعت از"
                  >
                  </v-text-field>
                </span>
                <date-picker
                  v-model="freeTimeFrom"
                  element="freeTimeFrom"
                  color="#00a7b7"
                  type="time"
                />
              </v-col>
              <v-col md="6" cols="12">
                <span id="freeTimeTo">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="freeTimeTo"
                    :editable="true"
                    class="date-input"
                    label="ساعت تا"
                  >
                  </v-text-field>
                </span>
                <date-picker
                  v-model="freeTimeTo"
                  element="freeTimeTo"
                  color="#00a7b7"
                  type="time"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="ms-auto">
            <v-btn class="mb-2 primary-btn" :loading="isBusy" @click="addNew()"
              >ثبت
            </v-btn>

            <v-btn
              class="red-btn mb-2 mr-2 text-light"
              @click="addFreeDialog = false"
              >لغو
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showResultDialog" width="600">
      <v-card style="border-radius: 20px" class="modal-card px-3 py-3">
        <v-card-title>
          <span class="h1 primary--text">نتیجه افزودن زمان آزاد</span>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-container>
            <v-row>
              <v-col class="h3 font-weight-bold primary--text"
                >انجام شده: {{ result.done }}</v-col
              >
              <v-col class="h3 font-weight-bold primary--text"
                >انجام نشده: {{ result.undone }}</v-col
              >
            </v-row>
            <hr />
            <div v-for="(item, index) in result.newResults" :key="index">
              <v-row>
                <v-col class="h3">زمان آغاز:</v-col>
                <v-col class="h3">{{ item.startTime }}</v-col>
              </v-row>
              <v-row>
                <v-col class="h3">زمان پایان:</v-col>
                <v-col class="h3">{{ item.finishTime }}</v-col>
              </v-row>
              <v-row>
                <v-col class="h3">وضعیت:</v-col>
                <v-col class="h3">{{ item.text }}</v-col>
              </v-row>
              <hr />
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="ms-auto">
            <v-btn color="primary-btn" @click="showResultDialog = false">
              <v-icon class="me-1">mdi-close-outline</v-icon>
              بستن
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeFreeDialog" max-width="600">
      <v-card class="py-3" style="border-radius: 20px">
        <v-card-title>
          <span class="h1">حذف زمان آزاد</span>
        </v-card-title>
        <v-card-text class="px-5 pb-0">
          <v-container>
            <v-row>
              <v-col>
                <h5 class="text-center">
                  آیا از حذف زمان آزاد به ساعت
                  {{ selected.time }}
                  اطمینان دارید؟
                </h5>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="ms-auto">
            <v-btn
              class="mb-2 me-2 text-light primary-btn"
              @click="removeFreeDialog = false"
            >
              خیر
            </v-btn>
            <v-btn
              class="mb-2 text-light red-btn"
              :loading="isBusy"
              @click="removeFree()"
              >بله
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmationModal" persistent width="880">
      <v-card style="border-radius: 20px">
        <v-container class="text-center pa-6">
          <div class="text-center py-4 mb-4">
            <v-icon class="green--text mb-1">mdi-check-decagram</v-icon>
            <span class="green--text" style="font-size: 20px">
              زمان ویزیت با موفقیت ثبت شد.
            </span>
          </div>
          <div class="text-center h3 blue-grey--text text--darken-3">
            <span style="line-height: 28px">
              همچنین میتوانید از طریق
              <span class="font-weight-bold">
                " خدمات درمانگاه‌
                <v-icon>mdi-arrow-left</v-icon>
                مدیریت پرداخت‌ها "
              </span>
              در منو، اقدام به پرداخت مبلغ ویزیت‌های ثبت شده‌ی خود نمایید.
            </span>
          </div>
          <div class="pt-10 pb-4 text-center">
            <v-btn
              to="/reception/ClinicFinancials"
              class="mt-2 py-5 secondary-btn"
              width="200"
              target="_blank"
            >
              مدیریت پرداخت‌ها
            </v-btn>
            <v-btn class="mt-2 py-5 primary-btn" width="200" :to="'/reception/DoctorsList'" :class="deviceType == 'mobile' ? '' : 'ms-2'">
              بازگشت به صفحه پزشکان
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newServiceDialog" width="800">
      <v-card class="cards pa-2">
        <span v-show="false">{{ reactivity }}</span>
        <v-card-title class="text-right">
          <h5>افزودن خدمت به درخواست</h5>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            :items="freeNames"
            label="نام خدمت"
            item-text="text"
            item-value="id"
            class="mx-auto my-1"
            outlined
            dense
            placeholder="انتخاب کنید"
            v-model="newService.name"
          ></v-autocomplete>
          <vuetify-money
            placeholder="قیمت"
            label="قیمت (ریال)"
            :valueWhenIsEmpty="whenIsEmpty"
            :options="vMoneyOptions"
            type="number"
            outlined
            dense
            class="mx-auto my-1"
            v-model="newService.cost"
          />
          <vuetify-money
            placeholder="هزینه"
            label="هزینه مصرفی (ریال)"
            :valueWhenIsEmpty="whenIsEmpty"
            :options="vMoneyOptions"
            type="number"
            outlined
            dense
            class="mx-auto my-1"
            v-model="newService.expense"
          />
          <vuetify-money
            placeholder="هزینه"
            label="هزینه اقلام حفاظتی (ریال)"
            :valueWhenIsEmpty="whenIsEmpty"
            :options="vMoneyOptions"
            type="number"
            outlined
            dense
            class="mx-auto my-1"
            v-model="newService.clinicShare"
          />
          <v-select
            :items="computedServiceTypes"
            label="شیوه ارائه خدمت"
            class="mx-auto my-1"
            outlined
            dense
            placeholder="انتخاب کنید"
            v-model="newService.type"
          />

          <v-btn
            class="primary-btn"
            @click="addFreeService(newService)"
            :disabled="!newService.name || !newService.cost || !newService.type"
            >ثبت</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- printDiv -->
    <div
      class="pa-2"
      v-show="false"
      style="float: left"
      id="printDiv"
      v-if="isMounted"
    >
      <div class="container mb-0 pa-3" style="border: 2px solid black">
        <div
          style="
            text-align: center;
            margin: auto;
            font-size: 15px;
            border-bottom: 4px solid black;
          "
        >
          <img src="/receiptLogo.png" width="100" height="100" alt="" />
          <br />
          درمانگاه شبانه روزی پارسیان البرز
        </div>

        <!-- inja -->
        <p style="direction: rtl; font-weight: 900; padding: 10px">
          نام پزشک:
          {{
            details.docId
              ? drNames.filter((x) => x.value == details.docId)[0].nameW
              : "-"
          }}
          <br />
          نام بیمار:
          {{
            details.patientId
              ? patientNames.filter((x) => x.id == details.patientId)[0].nameW
              : "-"
          }}
          <br />
          {{
            "نوبت: " +
            selectedDay.date +
            " - " +
            selectedDay.times[selectedTime[0]].time.split("-")[0] +
            " " +
            "الی" +
            " " +
            selectedDay.times[selectedTime[selectedTime.length - 1]].time.split(
              "-"
            )[1]
          }}
        </p>

        <b-table
          bordered
          v-if="visitType == 'public' || visitType == 'expert'"
          :fields="Fields"
          :items="Items"
          small="small"
          responsive
          style="padding: 10px"
        >
          <template v-slot:head()="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.label }}
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(number)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(cost)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ Number(data.item.cost).toLocaleString() }}
            </div>
          </template>
        </b-table>
        <b-table
          bordered
          v-else-if="visitType == 'دندانپزشکی'"
          :fields="Fields"
          :items="Items"
          small="small"
          responsive
          style="padding: 10px"
        >
          <template v-slot:head()="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.label }}
            </div>
          </template>
          <template v-slot:cell(name)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(number)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(cost)="data">
            <div style="text-align: center; vertical-align: middle">
              {{ Number(data.item.cost).toLocaleString() }}
            </div>
          </template>
        </b-table>
        <p v-else style="direction: rtl; font-weight: 900; padding: 10px">
          نوع درخواست: {{ visitType }}
        </p>
        <h4 style="padding: 10px">
          {{ "مبلغ کل:" + Number(details.cost).toLocaleString() + "ریال" }}
        </h4>
        <br />
        <div style="text-align: right; font-weight: 900; padding: 10px">
          وضعیت پرداخت: پرداخت نشده
        </div>
        <hr />
        <br />
      </div>
      <br />
      <!-- <barcode value="http://my.parsianalborz.com" displayValue="false" width="2" height="60">
        </barcode>   -->
      <h4 style="text-align: center">http://my.parsianalborz.com</h4>
    </div>
  </div>
</template>

<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { mapGetters } from "vuex";
function checkCodeMeli(code) {
  var L = code.length;

  if (L < 8 || parseInt(code, 10) == 0) return false;
  code = ("0000" + code).substr(L + 4 - 10);
  if (parseInt(code.substr(3, 6), 10) == 0) return false;
  var c = parseInt(code.substr(9, 1), 10);
  var s = 0;
  for (var i = 0; i < 9; i++) s += parseInt(code.substr(i, 1), 10) * (10 - i);
  s = s % 11;
  return (s < 2 && c == s) || (s >= 2 && c == 11 - s);
}
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      opens: 0,
      monthsName: [
        "فروردین",
        "اردیبهشت",
        "خرداد",
        "تیر",
        "مرداد",
        "شهریور",
        "مهر",
        "آبان",
        "آذر",
        "دی",
        "بهمن",
        "اسفند",
      ],
      daysOfWeek: [
        {
          text: "یکشنبه",
          value: 1,
        },
        {
          text: "دوشنبه",
          value: 2,
        },
        {
          text: "سه‌شنبه",
          value: 3,
        },
        {
          text: "چهارشنبه",
          value: 4,
        },
        {
          text: "پنجشنبه",
          value: 5,
        },
        {
          text: "جمعه",
          value: 6,
        },
        {
          text: "شنبه",
          value: 0,
        },
      ],

      currentYear: moment(new Date()).format("jYYYY"),
      currentMonth: moment(new Date()).format("jMM"),
      year: moment(new Date()).format("jYYYY"),
      month: moment(new Date()).format("jMM"),
      day: moment(new Date()).format("jDD"),
      selected: {},
      selectedyear: "",
      selectedMonth: "",
      numberOfDay: 0,
      numberOfWeekDay: 0,
      allDates: [],
      allTimes: [],
      showTimes: false,
      schedules: [],
      colorsGuidance: false,
      isBusy: false,
      deviceType: "",
      requestDialog: false,
      userInfo: [],
      selectedTime: [],
      editInsuranceDialog: false,
      insuranceInfo: {
        insurType: "",
        insuranceExpirationDate: "",
      },
      patientName: "",
      visitsBusy: false,
      patientNames: [],
      filterName: "",
      filterNatCode: "",
      filterCode: "",
      patientInfo: [],
      changeMobileDialog: false,
      newMobile: "",
      insuranceExpirationDate: "",
      insuranceLastSheet: false,
      insurancePageNo: "",
      Busy: false,
      busySubmit: true,
      freeNames: [],
      dentistFreeNames: [],
      date: moment(new Date()).format("jYYYY/jMM/jDD HH:MM"),
      dateDate: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTime: moment(new Date()).format("HH:mm"),
      states: [
        { value: "فعال", text: "فعال" },
        { value: "غیرفعال", text: "غیرفعال" },
      ],
      outDoctor: "",
      isOut: false,
      insurType: "",
      newCurrentPage: 1,
      dentistNewCurrentPage: 1,
      newPerPage: 10,
      dentistNewPerPage: 10,
      newTotalRows: "",
      dentistNewTotalRows: "",
      newFilter: "",
      dentistNewFilter: "",
      busy: false,
      freeServiceItems: [
        {
          name: "",
          cost: "",
          patientCost: "",
          expense: "",
          clinicShare: "",
          type: "",
        },
      ],
      dentistFreeServiceItems: [
        {
          name: "",
          cost: "",
          patientCost: "",
          expense: "",
          clinicShare: "",
          type: "",
        },
      ],
      newPatientDialog: false,
      addPatientBtnLoading: false,
      genders: [
        { value: "زن", text: "زن" },
        { value: "مرد", text: "مرد" },
      ],
      freeOptions: [
        {
          value: "خدمات پرستاری",
          text: "خدمات پرستاری",
        },
        {
          value: "ویزیت",
          text: "ویزیت",
        },
        {
          value: "ویزیت تخصص",
          text: "ویزیت تخصص",
        },
        { value: "خدمات پزشک", text: "خدمات پزشک" },
      ],
      freeServiceFields: [
        { key: "name", label: "نام خدمت" },
        { key: "cost", label: "قیمت (ریال)" },
        { key: "expense", label: "هزینه مصرفی (ریال)" },
        { key: "clinicShare", label: "هزینه اقلام حفاظتی (ریال)" },
        { key: "type", label: "شیوه ارائه خدمت" },
        { key: "operation", label: "عملیات" },
      ],
      dentistFreeServiceFields: [
        { key: "name", label: "نام خدمت" },
        { key: "cost", label: "قیمت (ریال)" },
        { key: "expense", label: "هزینه مصرفی (ریال)" },
        { key: "clinicShare", label: "هزینه اقلام حفاظتی (ریال)" },
        { key: "operation", label: "عملیات" },
      ],
      serviceFields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام خدمت" },
        { key: "cost", label: "قیمت (ریال)" },
        { key: "patientCost", label: "پرداختی بیمار" },
        { key: "clinicShare", label: "هزینه اقلام حفاظتی (ریال)" },
        { key: "type", label: "شیوه ارائه خدمت" },
        { key: "operation", label: "عملیات" },
      ],
      dentistServiceFields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام خدمت" },
        { key: "cost", label: "قیمت (ریال)" },
        { key: "patientCost", label: "پرداختی بیمار" },
        { key: "clinicShare", label: "هزینه اقلام حفاظتی (ریال)" },
        { key: "operation", label: "عملیات" },
      ],
      serviceFilter: "",
      dentistServiceFilter: "",
      visitType: "",
      requestTypes: [
        { value: "public", text: "درمانگاه عمومی" },
        { value: "expert", text: "درمانگاه تخصصی" },
        //{ value: "دندانپزشکی", text: "دندانپزشکی" },
        { value: "مشاوره", text: "مشاوره" },
        { value: "پاراکلینیک", text: "پاراکلینیک", disabled: true },
        // { value: "زنان", text: "زنان" },
        // { value: "مامائی", text: "مامائی" },
      ],
      serviceTotalRows: "",
      dentistTotalRows: "",
      serviceCurrentPage: 1,
      dentistServiceCurrentPage: 1,
      servicePerPage: 10,
      dentistServicePerPage: 10,
      serviceItems: [],
      dentistItems: [],
      newItems: [],
      dentistNewItems: [],
      reactivity: false,
      price: "",
      addFreeDialog: false,
      freeDateFrom: moment(new Date()).format("jYYYY-jMM-jDD"),
      freeDateTo: moment(new Date()).format("jYYYY-jMM-jDD"),
      freeTimeFrom: moment(new Date()).format("HH:mm"),
      freeTimeTo: moment(new Date()).add(2, "hours").format("HH:mm"),
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      showResultDialog: false,
      result: {},
      removeFreeDialog: false,
      timeLoading: false,
      confirmationModal: false,
      isMounted: false,
      doctorId: "",
      details: {},
      Fields: [
        { key: "name", label: "خدمت" },
        { key: "number", label: "تعداد" },
        { key: "cost", label: "قیمت" },
      ],
      pageLoaded: false,
      calendarInfo: false,
      drList: false,
      newPatient: {
        name: "",
        gender: "",
        mobile: "",
        insurType: "",
        insurNo: "",
        natCode: "",
        insuranceLastSheet: false,
      },
      newServiceDialog: false,
      newService: {},
    };
  },
  computed: {
    ...mapGetters(["insurTypes", "drNames"]),
    yearMonthComputed() {
      if (
        this.monthDict[moment(this.startDate).format("MM") - 1] ==
          this.monthDict[moment(this.endDate).format("MM") - 1] &&
        moment(this.startDate).format("YYYY") ==
          moment(this.endDate).format("YYYY")
      ) {
        return true;
      } else {
        return false;
      }
    },
    selectedDay() {
      let selected = "";
      this.schedules.forEach((day) => {
        let stringDay = JSON.stringify(day);
        if (stringDay.includes("انتخاب شده")) {
          selected = day;
        }
      });
      return selected;
    },
    requestedTimes() {
      if (this.selectedDay) {
        let selectedTimes = this.selectedDay.times.filter(
          (x) => x.status == "انتخاب شده"
        );
        return selectedTimes;
      } else {
        return [];
      }
    },
    weekDays() {
      return [
        "شنبه",
        "یکشنبه",
        "دوشنبه",
        "سه‌شنبه",
        "چهارشنبه",
        "پنجشنبه",
        "جمعه",
      ];
    },
    checkPassedDate() {
      let passedDates = [];
      this.allDates.length
        ? this.allDates.forEach((x) => {
            x >= moment(new Date()).format("jYYYY/jMM/jDD")
              ? passedDates.push(x)
              : "";
          })
        : "";
      return passedDates;
    },
    computedDisabled() {
      if (this.patientName && this.selectedTime.length != 0 && this.visitType) {
        if (this.visitType == "مشاوره") {
          if (this.price) {
            return false;
          } else {
            return true;
          }
        } else if (this.visitType == "دندانپزشکی") {
          if (this.dentistNewItems.length != 0) {
            return false;
          } else {
            return true;
          }
        } else {
          if (this.newItems.length != 0) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    },
    computedServiceFields() {
      if (this.deviceType == "mobile") {
        return [
          { key: "name", label: "نام خدمت" },
          { key: "type", label: "شیوه ارائه خدمت" },
          { key: "operation", label: "عملیات" },
        ];
      } else {
        return [
          { key: "index", label: "#" },
          { key: "name", label: "نام خدمت" },
          { key: "cost", label: "قیمت (ریال)" },
          { key: "patientCost", label: "پرداختی بیمار" },
          { key: "clinicShare", label: "هزینه اقلام حفاظتی (ریال)" },
          { key: "type", label: "شیوه ارائه خدمت" },
          { key: "operation", label: "عملیات" },
        ];
      }
    },
    computedServiceTypes() {
      if (this.visitType == "public") {
        return ["خدمات پرستاری", "ویزیت", "خدمات پزشک", "اقلام مصرفی"];
      } else if (this.visitType == "expert") {
        return ["ویزیت تخصصی", "خدمات تخصصی"];
      }
    },
  },
  methods: {
    computedDayColor(number) {
      let chooseColor;
      if (number < 10) {
        chooseColor = this.schedules.filter(
          (x) => x.date == this.year + "/" + this.month + "/0" + number
        )[0].color;
      } else if (number >= 10) {
        chooseColor = this.schedules.filter(
          (x) => x.date == this.year + "/" + this.month + "/" + number
        )[0].color;
      }

      if (chooseColor == "none") {
        return "freeTime";
      } else if (chooseColor == "all") {
        return "busyTime";
      } else if (chooseColor == "semi") {
        return "semiTime";
      }
    },
    isSemi(number) {
      let chooseColor;
      if (number < 10) {
        chooseColor = this.schedules.filter(
          (x) => x.date == this.year + "/" + this.month + "/0" + number
        )[0]
          ? this.schedules.filter(
              (x) => x.date == this.year + "/" + this.month + "/0" + number
            )[0].color
          : "";
      } else if (number >= 10) {
        chooseColor = this.schedules.filter(
          (x) => x.date == this.year + "/" + this.month + "/" + number
        )[0]
          ? this.schedules.filter(
              (x) => x.date == this.year + "/" + this.month + "/" + number
            )[0].color
          : "";
      }

      if (chooseColor == "semi") {
        return true;
      } else {
        return false;
      }
    },
    computedLeftTimes(number) {
      let times;
      if (number < 10) {
        times = this.schedules
          .filter(
            (x) => x.date == this.year + "/" + this.month + "/0" + number
          )[0]
          .times.filter((y) => y.status == "free");
      } else if (number >= 10) {
        times = this.schedules
          .filter(
            (x) => x.date == this.year + "/" + this.month + "/" + number
          )[0]
          .times.filter((y) => y.status == "free");
      }
      return times.length;
    },
    openAdd() {
      this.addFreeDialog = true;
      this.freeDateFrom = moment(new Date()).format("jYYYY-jMM-jDD");
      this.freeDateTo = moment(new Date()).format("jYYYY-jMM-jDD");
      this.freeTimeFrom =
        moment(new Date().getHours()) + ":" + moment(new Date().getMinutes());
      this.freeTimeTo = moment(new Date()).add(2, "hours").format("HH:mm");
    },
    docVisits() {
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/visitTimes/doctorAllVisitTimes",
          {
            userId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            //doctor scheduls
            this.schedules = res.data;
            var currentDate = moment(new Date()).format("jYYYY/jMM/jDD");
            var currentTime = moment(new Date()).format("HH:mm");
            setTimeout(() => {
              this.schedules.forEach((x) => {
                if (x.date == currentDate) {
                  x.times.forEach((y) => {
                    let startTime = y.time.split(" - ")[0];
                    startTime < currentTime && y.status != "catched"
                      ? (y.status = "passed")
                      : "";
                  });
                }
              });

              this.schedules.forEach((x) => {
                this.allDates.push(x.date);
              });
            }, 300);
            this.pageLoaded = true;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
        });
    },
    selectDate(number) {
      if (number < 10) {
        this.day = "0" + number;
      } else {
        this.day = number;
      }
      this.selectedyear = this.year;
      this.selectedMonth = this.month;

      this.allTimes = this.schedules.filter(
        (x) => x.date == this.year + "/" + this.month + "/" + this.day
      )[0].times;
      this.showTimes = true;
    },
    persianMonthName(month) {
      let monthName;
      monthName = this.monthsName[Number(month) - 1];
      return monthName;
    },
    getNumberOfDays() {
      if (1 <= parseInt(this.month) && parseInt(this.month) < 7) {
        this.numberOfDay = 31;
      } else if (7 <= parseInt(this.month) && parseInt(this.month) != 12) {
        this.numberOfDay = 30;
      } else if (parseInt(this.month) == 12) {
        let isLead = moment(this.year + "/12/30", "jYYYY/jMM/jDD").isValid();
        if (isLead) {
          this.numberOfDay = 30;
        } else this.numberOfDay = 29;
      }

      if (parseInt(this.day) > parseInt(this.numberOfDay)) {
        this.day = this.numberOfDay;
      }
      let date = this.year + "/" + this.month + "/" + "01";

      let day = moment(date, "jYYYY/jMM/jDD").format("d");
      this.numberOfWeekDay = this.daysOfWeek[day];

      // alert(this.numberOfWeekDay);
      // alert(this.numberOfDay);
    },
    checkDate(number) {
      if (number < 10) {
        if (
          this.checkPassedDate.includes(
            this.year + "/" + this.month + "/0" + number
          )
        ) {
          return true;
        } else return false;
      } else {
        if (
          this.checkPassedDate.includes(
            this.year + "/" + this.month + "/" + number
          )
        ) {
          return true;
        } else return false;
      }
    },
    pick(date, index, status) {
      // for deselecting a time
      if (
        status == "انتخاب شده" &&
        (index == this.selectedTime[0] ||
          index == this.selectedTime[this.selectedTime.length - 1])
      ) {
        /*checking to avoiding deselecting a time between selected times*/
        this.schedules.filter((x) => x.date == date)[0].times[index].status =
          "free";
        this.selectedTime.splice(this.selectedTime.indexOf(index), 1);
      } else if (status == "catched" || status == "canceled") {
        return;
        // for selecteing a time
      } else {
        if (!this.selectedDay || date == this.selectedDay.date) {
          /* check to avoid selecting times from different dates */
          if (!this.selectedTime.length) {
            this.schedules.filter((x) => x.date == date)[0].times[
              index
            ].status = "انتخاب شده";
            this.selectedTime.push(index); /* pushing first selected time */
          } else if (index == this.selectedTime[0] - 1) {
            this.schedules.filter((x) => x.date == date)[0].times[
              index
            ].status = "انتخاب شده";
            this.selectedTime.unshift(
              index
            ); /* unshifting index of selected time to bottom of selected times because it's before them*/
          } else if (
            index ==
            this.selectedTime[this.selectedTime.length - 1] + 1
          ) {
            this.schedules.filter((x) => x.date == date)[0].times[
              index
            ].status = "انتخاب شده";
            this.selectedTime.push(
              index
            ); /* pushing index of selected time to top of selected times because it's after them*/
          } else {
            this.toast("زمان‌های انتخاب شده باید پشت سر هم باشند", "warning");
          }
        } else {
          this.toast("زمان‌های انتخاب شده باید پشت سر هم باشند", "warning");
        }
      }
    },
    goToDate() {
      this.allTimes = this.schedules.filter(
        (x) => x.date == this.selectedDay.date
      )[0].times;
      this.year = this.selectedDay.date.split("/")[0];
      this.month = this.selectedDay.date.split("/")[1];
      this.day = this.selectedDay.date.split("/")[2];
      this.selectDate(this.day);
    },
    editPatientInsurance() {
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/editInsurance",
          {
            patientId: this.patientName,
            insuranceId: this.insuranceInfo.insuranceId,
            insuranceExpirationDate: this.insuranceInfo.insuranceLastSheet
              ? ""
              : this.insuranceInfo.insuranceExpirationDate,
            insuranceLastSheet: this.insuranceInfo.insuranceLastSheet,
            insurNo: this.insuranceInfo.insurNo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.toast("بیمه با موفقیت ویرایش شد.", "success");
            this.editInsuranceDialog = false;
            this.patientVisitsNumber();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    patientVisitsNumber() {
      this.visitsBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/visitsNumber",

          {
            patientId: this.patientName,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.visitsBusy = false;
          if (res.status == 200) {
            this.patientInfo = res.data;
            this.insuranceInfo = JSON.parse(JSON.stringify(res.data));
            this.reactivity = !this.reactivity;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.visitsBusy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    changeMobileNumber() {
      this.changeBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/editMobile",

          {
            patientId: this.patientName,
            mobile: this.newMobile,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.changeBusy = false;
          if (res.status == 200) {
            this.changeMobileDialog = false;
            this.newMobiles = "";
            this.patientVisitsNumber();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.changeBusy = false;
          this.changeMobileDialog = false;
          this.newMobile = "";
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    filterPatients() {
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/filter",

          {
            name: this.filterName,
            natCode: this.filterNatCode || "",
            code: this.filterCode || "",
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.patientName = "";
            this.patientNames = res.data.map((x) => {
              return {
                id: x.id,
                text: x.name,
                insurance: x.insuranceId,
                nameW: x.nameW,
                code: x.code,
              };
            });
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    isID(id) {
      // NOTE (m-isID) to check the validity of the national code
      return id
        ? checkCodeMeli(id)
          ? true
          : "کدملی وارد شده صحیح نمی‌باشد"
        : true;
    },
    checkNatCode(id) {
      return id ? (checkCodeMeli(id) ? true : false) : true;
    },
    openNewPatient() {
      (this.newPatient = {
        firstName: "",
        lastName: "",
        gender: "",
        mobile: "",
        insurType: "",
        insurNo: "",
        natCode: "",
        insuranceExpirationDate: "",
        insuranceLastSheet: false,
      }),
        (this.newPatientDialog = true);
    },
    submitNewPatient() {
      this.addPatientBtnLoading = true;
      this.isBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/patients/new",

          {
            gender: this.newPatient.gender,
            firstName: this.newPatient.firstName,
            lastName: this.newPatient.lastName,
            mobile: this.newPatient.mobile,
            insuranceId: this.newPatient.insurType,
            insurNo: this.newPatient.insurNo,
            natCode: this.newPatient.natCode,
            insuranceExpirationDate: this.newPatient.insuranceLastSheet
              ? ""
              : this.newPatient.insuranceExpirationDate,
            insuranceLastSheet: this.newPatient.insuranceLastSheet,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.filterName =
              this.newPatient.firstName + " " + this.newPatient.lastName;
            this.filterNatCode = this.newPatient.natCode;
            this.patientName = res.data;
            this.filterPatients();
            this.toast("بیمار با موفقیت ثبت شد.", "success");
            this.newPatient = {
              firstName: "",
              lastName: "",
              gender: "",
              mobile: "",
              insurType: "",
              insurNo: "",
              natCode: "",
              insuranceExpirationDate: "",
              insuranceLastSheet: false,
            };
            this.newPatientDialog = false;

            this.isBusy = false;
            this.addPatientBtnLoading = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.isBusy = false;
            this.addPatientBtnLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
          this.addPatientBtnLoading = false;
        });
    },
    popup() {
      var myWindow = window.open("#", "printDiv", "height=auto,width=800");
      myWindow.document.write(
        "<html><head><link rel='stylesheet' href='/receipt.css'><title>Receipt</title>"
      );
      myWindow.document.write("</head><body>");
      myWindow.document.write(document.getElementById("printDiv").innerHTML);
      myWindow.document.write("</body></html>");
      myWindow.document.close();

      myWindow.onload = function () {
        myWindow.focus();
        setTimeout(() => {
          myWindow.print();
          myWindow.close();
        }, 500);
      };
    },
    submit() {
      this.$http
        .post(
          this.baseUrl + "/clinic/visits/booking",
          {
            docId: this.$route.params.id,
            visitTimes: this.requestedTimes,
            patientId: this.patientName,
            visitType:
              this.visitType == "مشاوره" || this.visitType == "دندانپزشکی"
                ? this.visitType
                : "خدمات عمومی",
            insuranceId: this.patientInfo.insuranceId,
            date: this.dateDate + this.dateTime,
            insuranceExpirationDate: this.patientInfo.insuranceLastSheet
              ? ""
              : this.patientInfo.insuranceExpirationDate,
            insuranceLastSheet: this.patientInfo.insuranceLastSheet,
            insurNo: this.patientInfo.insurNo,
            insurancePageNo: this.insurancePageNo,
            services:
              this.visitType == "دندانپزشکی"
                ? JSON.stringify(this.dentistNewItems)
                : JSON.stringify(this.newItems),
            totalCost:
              this.visitType == "مشاوره"
                ? this.price
                : this.visitType == "دندانپزشکی"
                ? this.dentistNewItems.length != 0
                  ? String(
                      this.dentistNewItems
                        .map((x) => x.patientCost)
                        .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                    )
                  : ""
                : this.newItems.length != 0
                ? String(
                    this.newItems
                      .map((x) => x.patientCost)
                      .reduce((a, b) => parseInt(a) + parseInt(b), 0)
                  )
                : "",
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.requestDialog = false;

            this.Items = res.data.services;
            this.details = res.data;
            this.toast("نوبت با موفقیت به ثبت رسید.", "success");
            this.isMounted = true;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .then(() => {
          this.popup();
          this.isMounted = false;
          this.confirmationModal = true;
          this.opens = 0;
        })
        .catch((err) => {
          console.log(err);
          this.toast("خطا: " + err.response.data, "error");
          //this.isMounted = false;
        });
    },
    addtoNewService(item) {
      this.serviceTotalRows = this.serviceItems.length;
      this.newItems.push(item);
      this.newTotalRows = this.newItems.length;
    },
    addtoNewDentistService(item) {
      this.dentistTotalRows = this.dentistItems.length;
      this.dentistNewItems.push(item);
      this.dentistNewTotalRows = this.dentistNewItems.length;
    },
    addFreeService(item) {
      item.patientCost = item.cost;
      item.clinicShare = item.clinicShare ? item.clinicShare : "0";
      item.expense = item.expense ? item.expense : "0";
      item.id = this.freeNames.filter((x) => x.id == item.name)[0].id;
      item.docPercent = this.freeNames.filter(
        (x) => x.id == item.name
      )[0].docPercent;
      item.name = "*" + this.freeNames.filter((x) => x.id == item.name)[0].text;
      this.serviceTotalRows = this.serviceItems.length;
      this.newItems.push(item);
      this.newTotalRows = this.newItems.length;
      this.freeServiceItems = [
        {
          name: "",
          cost: "",
          expense: "",
          clinicShare: "",
          patientCost: "",
          type: "",
        },
      ];
      this.newService = {};
      this.newServiceDialog = false;
    },
    addFreeDentistService(item) {
      item.patientCost = item.cost;
      item.clinicShare = item.clinicShare ? item.clinicShare : "0";
      item.expense = item.expense ? item.expense : "0";
      item.id = this.dentistFreeNames.filter((x) => x.id == item.name)[0].id;
      item.docPercent = this.dentistFreeNames.filter(
        (x) => x.id == item.name
      )[0].docPercent;
      item.name =
        "*" + this.dentistFreeNames.filter((x) => x.id == item.name)[0].text;
      this.dentistTotalRows = this.dentistItems.length;
      this.dentistNewItems.push(item);
      this.dentistNewTotalRows = this.dentistNewItems.length;
      this.dentistFreeServiceItems = [
        {
          name: "",
          cost: "",
          expense: "",
          clinicShare: "",
          patientCost: "",
          type: "",
        },
      ];
    },
    delService(order) {
      this.newItems.splice(order, 1);
    },
    dentistDelService(order) {
      this.dentistNewItems.splice(order, 1);
    },
    clinicServices() {
      if (this.visitType == "public" || this.visitType == "expert") {
        this.newItems = [];
        this.freeServiceItems = [
          {
            name: "",
            cost: "",
            expense: "",
            clinicShare: "",
            patientCost: "",
            type: "",
          },
        ];
        this.serviceFilter = "";
        this.serviceCurrentPage = 1;
        this.serviceBusy = true;
        this.$http
          .post(
            this.baseUrl + "/clinic/services/servicesPerClinic",

            {
              clinic: this.visitType,
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            this.serviceBusy = false;
            if (res.status == 200) {
              this.serviceItems = res.data;
              this.freeNames = this.serviceItems.map((x) => {
                return {
                  id: x.id,
                  text: x.name,
                  docPercent: x.docPercent,
                };
              });
              this.serviceTotalRows = this.serviceItems.length;
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            }
          })
          .catch((err) => {
            this.serviceBusy = false;
            this.toast("خطا: " + err.response.data, "error");
          });
      } else if (this.visitType == "دندانپزشکی") {
        this.dentistServices();
      }
    },
    dentistServices() {
      this.vLoading = true;
      this.serviceBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/dentist/services",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.vLoading = false;
          if (res.status == 200) {
            this.dentistItems = res.data;
            this.dentistFreeNames = this.dentistItems.map((x) => {
              return {
                id: x.id,
                text: x.name,
                docPercent: x.docPercent,
              };
            });
            this.serviceBusy = false;
            this.dentistTotalRows = this.dentistItems.length;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.vLoading = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },

    serviceonFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.serviceTotalRows = filteredItems.length;
      this.serviceCurrentPage = 1;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.newTotalRows = filteredItems.length;
      this.newCurrentPage = 1;
    },
    dentistServiceonFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.dentistTotalRows = filteredItems.length;
      this.dentistServiceCurrentPage = 1;
    },
    dentistonFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.dentistNewTotalRows = filteredItems.length;
      this.dentistNewCurrentPage = 1;
    },
    showNow() {
      this.year = this.currentYear;
      this.month = this.currentMonth;
      this.getNumberOfDays();
    },
    openRemoveModal(item) {
      this.selected = item;
      this.removeFreeDialog = true;
    },
    addNew() {
      this.isBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/visitTimes/newVisitTime",
          {
            userId: this.$route.params.id,
            startDate: this.freeDateFrom + this.freeTimeFrom,
            endDate: this.freeDateTo + this.freeTimeTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.isBusy = false;
          if (res.status == 201) {
            this.addFreeDialog = false;
            this.result = res.data;
            this.showResultDialog = true;

            this.toast("زمان مورد نظر با موفقیت به ثبت رسید.", "success");

            this.docVisits();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
        });
    },
    removeFree() {
      this.vLoading = true;
      this.timeLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/visitTimes/deleteVisitTime",
          {
            visitTimeId: this.selected.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.vLoading = false;
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.removeFreeDialog = false;
            this.docVisits();
            setTimeout(() => {
              this.allTimes = this.schedules.filter(
                (x) => x.date == this.year + "/" + this.month + "/" + this.day
              )[0].times;
              this.timeLoading = false;
            }, 500);
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.timeLoading = false;
          }
        })
        .catch((err) => {
          this.vLoading = false;
          this.timeLoading = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
  },
  mounted() {
    this.doctorId = this.$route.params.id;
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    this.docVisits();
    this.getNumberOfDays();
  },
};
</script>

<style lang="scss">
.modal-card {
  max-height: 580px;
  overflow-y: scroll;
}
.calendar {
  user-select: none;
  border-radius: 17px !important;
  float: right;
  width: 430px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%);

  .header {
    background-color: #00a7b7;
    padding: 33px 0;
    border-radius: 17px 17px 0 0 !important;
    position: relative;
    .calendar-info {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 200;
    }
    .calendar-btn {
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 200;
      .v-btn {
        border-radius: 8px;
        span {
          font-weight: bold;
        }
      }
    }
  }
  .month {
    color: #98aaca;
    font-size: 1.2rem;
    text-align: center;
    border-bottom: 1px solid #00a7b7;
    width: 85%;
    margin: auto;
  }
  .day-box {
    position: relative;
    width: 45px;
    height: 45px;
    margin: 4px;
    text-align: center;
    border-radius: 50%;
    padding-top: 17px;
    font-size: 1.4rem;
    margin-right: 6px;
    &:hover {
      background-color: #eeeeee;
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      cursor: none;
    }
    &.freeTime {
      background-color: #00695daf;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
    }
    &.busyTime {
      background-color: #df6a6ac2;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
    }
    &.semiTime {
      background-color: #f7bf50d9;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
    }
    .semi-number {
      position: absolute;
      top: -5px;
      right: -3px;
      background-color: #e33838;
      min-width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: white;
        font-size: 10px;
      }
    }
  }
  .weekday-box {
    width: 55px;
    height: 48px;
    text-align: center;
    border-radius: 50%;
    padding-top: 6px;
    font-size: 1.4rem;
    font-weight: bold;
  }
}

.date-result {
  border-radius: 17px !important;
  background-color: rgba(243, 243, 243, 0.482) !important;
  .delete-icon:hover {
    color: red !important;
  }
  .times-box {
    border-radius: 5px !important;
    border: 1px solid rgb(221, 221, 221);
    height: 230px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    &::-webkit-scrollbar {
      width: 10px;
      margin: 0 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgb(236, 236, 236);
      opacity: 0.5;
      border-radius: 1px !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(185, 185, 185);
      width: 10px;
      border-radius: 1px !important;
    }
  }
  .selected-date {
    &:hover {
      color: #94afd8;
      // text-decoration: underline;
    }
  }
}
@media only screen and (max-width: 600px) {
  .calendar {
    width: 270px;
    float: none;
    margin: 0 auto !important;
    .day-box {
      width: 30px;
      height: 30px;
      padding-top: 10px;
      margin: 1px;
    }
    .weekday-box {
      width: 32px;
      height: 32px;
      font-size: 1rem;
      font-weight: bold;
    }
  }
}

.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  display: none;
}

.v-expansion-panel::before {
  -webkit-transition: -webkit-box-shadow none !important;
  transition: -webkit-box-shadow none !important;
  transition: box-shadow none !important;
  transition: box-shadow none, -webkit-box-shadow none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: none;
}
.pi-text {
  font-size: 15px;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 80%;
}
@media (max-width: 600px) {
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
}
</style>
